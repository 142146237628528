<template>
	<div class="loading">
		<img class="loading-img" src="@/assets/images/loading.gif" alt="" />
	</div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import ajax from '@/api/api-manager';

const route = useRoute();
const router = useRouter();

// const params = {
// 	imp_uid: route.query.imp_uid,
// };
const data = new FormData();
data.append('imp_uid', route.query.imp_uid);
console.log(route.query);

if (route.query.imp_success != 'true') {
	router.replace('/payments');
}
// ajax(`user/user_certify`, data)
// 	.then((res) => {
// 		console.log(res);
// 		setTimeout(() => {
// 			router.replace('/mypage/account');
// 		}, 300);
// 		// user_certify_info.value = true;
// 	})
// 	.catch((err) => {
// 		console.log(err.response.data);
// 		alert('본인인증에 실패하였습니다.');
// 		router.replace('/mypage/account');
// 	});
</script>

<style scoped>
.loading {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loading-img {
	width: 100px;
	height: 100px;
}
</style>
